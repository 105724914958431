import { Counter } from "./components/Counter";
import { Dashboard } from "./components/Dashboard";
import { DataList } from "./components/DataList";
import { DataList2 } from "./components/DataList2";
import { FetchData } from "./components/FetchData";
import { FileUpload } from "./components/FileUpload";
import { Home } from "./components/Home";
import MMCompanyCountry from "./components/MM_Company_Country";
import MMKeywords from "./components/MM_Keyword";

const AppRoutes = [
    {
        index: true,
        element: <Dashboard />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/file-upload',
        element: <FileUpload />
    },
    {        
        path: '/data-list',
        element: <DataList />
    },
    {
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        path: '/mm-keyword',
        element: <MMKeywords />
    },
    {
        path: '/mm-company-country',
        element: <MMCompanyCountry />
    }
];

export default AppRoutes;
