import React, { Component, useState, useEffect } from 'react';
import { Col, Row, Table, DatePicker, Space } from 'antd';
import { SearchOutlined, ReloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Modal, Upload  } from 'antd';
import { SalesByCountryList } from './SalesByCountryList';
import { SalesByCompanyList } from './SalesByCompanyList';
import { SalesByCountryChart } from './SalesByCountryChart';
import { DataList2 } from './DataList2';
import { SalesBySuppCompanyList } from './SalesBySuppCompanyList';
import DataListMuiTable from './DataListMuiTable';

const { RangePicker } = DatePicker;

export const Dashboard = () => {
    const [searchCriteria, setSearchCriteria] = useState({ rdr: 0, date: [], dateString: [] });
    const [searchCondition, setSearchCondition] = useState({ rdr: 0, date: [], dateString: [] });
    const [searchCountry, setSearchCountry] = useState();
    const [isProgress, setProgress] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [open, setOpen] = useState(false);
    const [chartType, setChartType] = useState();

    useEffect(() => {
        console.log('searchCountry', searchCountry);
    }, [searchCountry]);

    const getSummary = async (e) => {
        setProgress(true);
        try {
            const res = await fetch("/dataupload/GetRecordSummary");

            if (res.ok) {
                setDataSource([]);
                res.json().then(v =>
                    v.map(m => setDataSource(oldArray => [...oldArray, { Excel_name: m.Excel_name, key: m.Excel_name, cnt: m.cnt }]))
                )
                    .finally(() => setProgress(false));

            } else {
                alert('Error occurred');
            }
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {

            console.log(dataSource);
            console.log("test");
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Space direction="horizontal" size={12}>
                        Date Range
                        <RangePicker picker="month" onChange={(_date, _dateString) => {
                            setSearchCriteria(oldObj => ({ ...oldObj, date: _date, dateString: _dateString, rdr: oldObj.rdr + 1 }))
                        }} />
                        <Button type="primary" icon={<SearchOutlined />} onClick={() => {
                            console.log('searchCriteria', searchCriteria);
                            setSearchCondition(oldObj => ({ ...searchCriteria, rdr: oldObj.rdr + 1 }));
                            //this.forceUpdate();
                        }}>
                            Search
                        </Button>                        
                    </Space>
                </Col>
            </Row>
            <Row gutter={20 }>
                <Col lg={8} xs={24}>
                    <SalesByCountryList searchCondition={searchCondition} setSearchCountry={setSearchCountry} setOpen={setOpen} setChartType={setChartType} />
                </Col>
                <Col lg={8} xs={24}>
                    <SalesByCompanyList searchCondition={searchCondition} setSearchCountry={setSearchCountry} setOpen={setOpen} setChartType={setChartType} />
                </Col>
                <Col lg={8} xs={24}>
                    <SalesBySuppCompanyList searchCondition={searchCondition} setSearchCountry={setSearchCountry} setOpen={setOpen} setChartType={setChartType} />
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24}>                    
                    <Modal
                        centered
                        open={open}
                        onOk={() => setOpen(false)}
                        onCancel={() => setOpen(false)}
                        width={'90%'}
                    >
                        <SalesByCountryChart searchCondition={searchCondition} searchCountry={searchCountry} chartType={chartType} />
                    </Modal>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24}>
                    {/*<DataList2 searchCondition={searchCondition} />*/}
                    <DataListMuiTable searchCondition={searchCondition} />
                </Col>
            </Row>
        </>
    );

}
