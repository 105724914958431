import React, { useMemo, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
//import { data } from './makeData';

const DataListMuiTable = ({ searchCondition }) => {
    const [isProgress, setProgress] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (Object.keys(searchCondition).length !== 0 && searchCondition.dateString.length > 0) {
            getList();
        }
    }, [searchCondition]);

    const getList = async (e) => {
        setProgress(true);
        try {
            const res = await fetch("/dataupload/GetList/" + searchCondition.dateString[0] + "/" + searchCondition.dateString[1]);

            if (res.ok) {
                setData([]);
                res.json().then(v => {
                    setData(v);
                    setProgress(false);
                }).then(() => {
                    console.log("data",data);
                });

            } else {
                setProgress(false);
                alert('Error occurred');
            }
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {
            console.log("test");
        }
    }

    const totalQty = useMemo(
        () => data.reduce((acc, curr) => acc + curr.Quantity, 0),
        [data],
    );

    const totalValue = useMemo(
        () => data.reduce((acc, curr) => acc + curr.Value_USD, 0),
        [data],
    );

    const priceRange = (pVal) => {
        console.log('pVal', pVal);
        if (pVal == "1") {
            return "< 1"
        } else if (pVal == "2") {
            return "1 - 2"
        } else if (pVal == "3") {
            return "2 - 2.5"
        } else if (pVal == "4") {
            return "2.5 - 3.5"
        } else {
            return "> 3.5"
        }
    }

    const columns = useMemo(
        () => [
            {
                header: 'Date',
                accessorKey: 'Date',
                enableGrouping: false, //do not let this column be grouped
            },
            {
                header: 'Month/Year',
                accessorKey: 'Month_Year',
            },
            {
                header: 'Quantity',
                accessorKey: 'Quantity',
                aggregationFn: 'sum',
                AggregatedCell: ({ cell, table }) => (
                    <>
                        Total by{' '}
                        {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
                        <Box
                            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
                        >
                            <br />
                            {cell.getValue() > 1000 ?
                                Math.round(cell.getValue() / 1000)?.toLocaleString?.('en-US') + ' K' :
                                Math.round(cell.getValue()?.toLocaleString('en-US'))}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        Total Quantity:
                        <Box color="warning.main">
                            {totalQty > 1000 ?
                                Math.round(totalQty / 1000)?.toLocaleString?.('en-US') + ' K' :
                                Math.round(totalQty?.toLocaleString('en-US'))}
                        </Box>
                    </Stack>
                ),
                minSize: 200,
                maxSize: 500,
                enableMultiSort: true,
            },
            {
                header: 'Price (USD)',
                accessorKey: 'Price_USD',
                aggregationFn: 'max',
                AggregatedCell: ({ cell, table }) => (
                    <>
                        Highest price by{' '}
                        {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
                        <Box
                            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
                        >
                            {cell.getValue()?.toLocaleString('en-US')}
                        </Box>
                    </>
                ),
                minSize: 200
            },
            {
                header: 'Value (USD)',
                accessorKey: 'Value_USD',
                aggregationFn: 'sum',
                AggregatedCell: ({ cell, table }) => (
                    <>
                        Total by{' '}
                        {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
                        <Box
                            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
                        >
                            {cell.getValue()?.toLocaleString?.('en-US')}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        Total Value:
                        <Box color="warning.main">
                            {totalValue > 1000 ?
                                Math.round(totalValue / 1000)?.toLocaleString?.('en-US') + ' K' :
                                Math.round(totalValue?.toLocaleString('en-US'))}
                        </Box>
                    </Stack>
                ),
                minSize: 200
            },
            {
                header: 'Price Range',
                accessorKey: 'Price_Range',
                minSize: 100,
                Cell: ({ cell }) => (
                    <span>${priceRange(cell.getValue())}</span>
                ),
                enableMultiSort: true
            },
            {
                header: 'Export Country',
                accessorKey: 'Export_Country',
                minSize: 250
            },
            {
                header: 'Export Company',
                accessorKey: 'Export_Company',
                minSize: 250
            },
            {
                header: 'Import Country',
                accessorKey: 'Import_Country',
                minSize: 250,
                Cell: ({ cell }) => (
                    <span>{cell.getValue().toUpperCase()}</span>
                ),
            },
            {
                header: 'Import Company',
                accessorKey: 'Import_Company',
                minSize: 250
            },
            {
                header: 'Import Company Address',
                accessorKey: 'Import_Company_Address',
            },
            {
                header: 'Customs Branch',
                accessorKey: 'Customs_Branch',
            },
            {
                header: 'Description of Goods',
                accessorKey: 'Description_Of_Goods',
            },            
            {
                header: 'Price Conditions',
                accessorKey: 'Price_Conditions',
            },




            //{
            //    header: 'Age',
            //    accessorKey: 'age',
            //    aggregationFn: 'max', //show the max age in the group (lots of pre-built aggregationFns to choose from)
            //    //required to render an aggregated cell
            //    AggregatedCell: ({ cell, table }) => (
            //        <>
            //            Oldest by{' '}
            //            {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
            //            <Box
            //                sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            //            >
            //                {cell.getValue()}
            //            </Box>
            //        </>
            //    ),
            //    Footer: () => (
            //        <Stack>
            //            Max Age:
            //            <Box color="warning.main">{Math.round(maxAge)}</Box>
            //        </Stack>
            //    ),
            //},
            //{
            //    header: 'Gender',
            //    accessorKey: 'gender',
            //    //optionally, customize the cell render when this column is grouped. Make the text blue and pluralize the word
            //    GroupedCell: ({ cell, row }) => (
            //        <Box sx={{ color: 'primary.main' }}>
            //            <strong>{cell.getValue()}s </strong> ({row.subRows?.length})
            //        </Box>
            //    ),
            //},
            //{
            //    header: 'State',
            //    accessorKey: 'state',
            //},
            //{
            //    header: 'Salary',
            //    accessorKey: 'salary',
            //    aggregationFn: 'mean',
            //    //required to render an aggregated cell, show the average salary in the group
            //    AggregatedCell: ({ cell, table }) => (
            //        <>
            //            Average by{' '}
            //            {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
            //            <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            //                {cell.getValue()?.toLocaleString?.('en-US', {
            //                    style: 'currency',
            //                    currency: 'USD',
            //                    minimumFractionDigits: 0,
            //                    maximumFractionDigits: 0,
            //                })}
            //            </Box>
            //        </>
            //    ),
            //    //customize normal cell render on normal non-aggregated rows
            //    Cell: ({ cell }) => (
            //        <>
            //            {cell.getValue()?.toLocaleString?.('en-US', {
            //                style: 'currency',
            //                currency: 'USD',
            //                minimumFractionDigits: 0,
            //                maximumFractionDigits: 0,
            //            })}
            //        </>
            //    ),
            //    Footer: () => (
            //        <Stack>
            //            Average Salary:
            //            <Box color="warning.main">
            //                {averageSalary?.toLocaleString?.('en-US', {
            //                    style: 'currency',
            //                    currency: 'USD',
            //                    minimumFractionDigits: 0,
            //                    maximumFractionDigits: 0,
            //                })}
            //            </Box>
            //        </Stack>
            //    ),
            //},
        ],
        [totalQty]
        //[averageSalary, maxAge],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnResizing
            enableGrouping
            enableStickyHeader
            enableStickyFooter
            enableMultiSort
            initialState={{
                density: 'comfortable',
                expanded: false, //expand all groups by default
                grouping: ['Import_Country'], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 20 },
                sorting: [{ id: 'Quantity', desc: true }], //sort by state by default
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            muiTableContainerProps={{ sx: { maxHeight: 700 } }}
        />
    );
};

export default DataListMuiTable;
