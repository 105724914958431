import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Row, Col, Form, Input, Button, message } from 'antd';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';

const MMCompanyCountry = () => {
    const [isProgress, setProgress] = useState(false);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const [tableData, setTableData] = useState(() => data);

    useEffect(() => {
        getList();
    }, []);

    const getList = async (e) => {
        setProgress(true);
        try {
            const res = await fetch("/countrymaint");

            if (res.ok) {
                setData([]);
                res.json().then(v => {
                    setData(v);
                    setProgress(false);
                }).then(() => {
                    console.log("data", data);
                });

            } else {
                setProgress(false);
                alert('Error occurred');
            }
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {
            console.log("test");
        }
    }

    const columns = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'ID_MM_Company_Country',
                enableHiding: false,
                enableGrouping: false, //do not let this column be grouped
                maxSize: 50
            },
            {
                header: 'Company',
                accessorKey: 'Import_Company',
                enableGrouping: false, //do not let this column be grouped
            },
            {
                header: 'Country',
                accessorKey: 'Import_Company_Country',
                enableGrouping: false, //do not let this column be grouped
            }
        ],
        []
    );

    const onSubmit = async (values) => {
        setProgress(true);
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            };
            console.log('JSON.stringify(encodeURI(values))', JSON.stringify(values));
            const res = await fetch("/countrymaint", requestOptions);

            if (res.ok) {
                message.success(`Record created successfully`);
                formRef.current?.resetFields();
            } else {
                setProgress(false);
                message.error('Error occurred');
            }
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {
            getList();
        }
    }

    const handleDeleteRow = useCallback(
        async (row) => {
            //if (
            //    !confirm(`Are you sure you want to delete ${row.getValue('Excel_Keyword_Extract')}`)
            //) {
            //    return;
            //}

            //send api delete request here, then refetch or update local table data for re-render
            try {
                const requestOptions = {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' }
                };
                console.log('row', row.getValue("ID_MM_Company_Country"));
                const res = await fetch("/countrymaint/" + row.getValue("ID_MM_Company_Country"), requestOptions);

                if (res.ok) {
                    message.success(`Record deleted successfully`);
                    data.splice(row.index, 1);
                    setData([...data]);
                } else {
                    message.error('Error occurred');
                }
            } catch (ex) {
                console.log("error", ex.toString());
            }

        },
        [data],
    );

    const SyncCountry = async () => {
        try {
            setProgress(true);
            const res = await fetch("/countrymaint/SyncCountry");

            if (res.ok) {
                message.success(`Sync successfully`);
            } else {
                message.error('Error occurred during sync');
            }
        } catch (ex) {
            message.error('Error occurred during sync ' + ex.toString());
            console.log("error", ex.toString());
        } finally {
            setProgress(false);
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <h3>Company country</h3>
                </Col>
            </Row>
            <Row>
                <Col lg={4} xs={24}>
                    <Form
                        form={form}
                        ref={formRef}
                        layout="vertical"
                        onFinish={onSubmit}
                        style={{
                            paddingRight: '20px'
                        }}
                    >
                        <Form.Item
                            label="Company"
                            required
                            tooltip="This is a required field"
                            name="Import_Company"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'This is a required field',
                                },
                            ]}
                        >
                            <Input placeholder="new company" />
                        </Form.Item>
                        <Form.Item
                            label="Country"
                            required
                            tooltip="This is a required field"
                            name="Import_Company_Country"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'This is a required field',
                                },
                            ]}
                        >
                            <Input placeholder="new country" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isProgress}>Submit</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={isProgress} onClick={SyncCountry}>Sync missing country</Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col lg={20} xs={24}>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableColumnResizing
                        enableGrouping
                        enableStickyHeader
                        enableStickyFooter
                        //initialState={{ columnVisibility: { ID_Keyword: false } }}
                        //initialState={{
                        //    density: 'compact',
                        //    expanded: true, //expand all groups by default
                        //    grouping: ['state'], //an array of columns to group by by default (can be multiple)
                        //    pagination: { pageIndex: 0, pageSize: 20 },
                        //    sorting: [{ id: 'state', desc: false }], //sort by state by default
                        //}}
                        state={{
                            isLoading: isProgress
                        }}
                        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                        muiTableContainerProps={{ sx: { maxHeight: 700 } }}
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="right" title="Delete">
                                    <IconButton color="error" onClick={() => {
                                        handleDeleteRow(row)
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MMCompanyCountry;
