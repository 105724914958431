import React, { Component, useState, useEffect } from 'react';
import { Col, Row, Table } from 'antd';
import { DeleteOutlined, ReloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, message, Upload, Space } from 'antd';

const { Dragger } = Upload;

export const FileUpload = () => {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [isProgress, setProgress] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [isRecProgress, setRecProgress] = useState([]);

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    useEffect(() => {
        getSummary();
    }, []);

    const getSummary = async (e) => {
        setProgress(true);
        try {
            const res = await fetch("/dataupload/GetRecordSummary");

            if (res.ok) {
                setDataSource([]);
                res.json().then(v =>
                    v.map(m => setDataSource(oldArray => [...oldArray, { Excel_name: m.Excel_name, key: m.Excel_name, cnt: m.cnt }]))
                )
                    .finally(() => setProgress(false));

            } else {
                alert('Error occurred');
            }
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {

            console.log(dataSource);
            console.log("test");
        }
    }

    const delData = async (e) => {
        setProgress(true);
        try {
            const res = await fetch("/dataupload", { method: "DELETE" });

            if (res.ok) {
                setDataSource([]);
            } else {
                alert('Error occurred');
            }
            setProgress(false);
        } catch (ex) {
            setProgress(false);
            console.log("error", ex.toString());
        } finally {

            console.log(dataSource);
            console.log("test");
        }
    }

    const delRec = async (_, record, index) => {
        console.log("_", _);
        console.log("record", record);
        console.log("index", index);
        setRecProgress((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = !newLoadings[index];
            return newLoadings;
        });

        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(encodeURI(record.key))
        };


        try {
            const res = await fetch("/dataupload/DeleteRec", requestOptions);

            if (res.ok) {
                //setDataSource([]);
                dataSource.splice(index, 1);
                setDataSource([...dataSource]);
                message.success(`record deleted successfully`);
            } else {
                message.error('Error occurred');
            }
        } catch (ex) {
            //setProgress(false);
            message.error('Error occurred ' + ex.toString());
            console.log("error", ex.toString());
        } finally {
            setRecProgress((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = !newLoadings[index];
                return newLoadings;
            });
            console.log(dataSource);
            console.log("test");
        }
    }

    const keywordFilter = async () => {
        try {
            const res = await fetch("/dataupload/GetRecordFiltered");

            if (res.ok) {                
                message.success(`record filtered successfully`);
            } else {
                message.error('Error occurred during filtering');
            }
        } catch (ex) {
            message.error('Error occurred during filtering ' + ex.toString());
            console.log("error", ex.toString());
        } finally {
            _fileCnt = 0;
        }
    }

    var _fileCnt = 0;
    const props = {
        name: 'file',
        multiple: true,
        //maxCount: 5,
        listType: "picture",
        action: '/dataupload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {

            console.log('info', info);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                _fileCnt++
                message.success(`${info.file.name} file uploaded successfully`);
                //getSummary();
            } else if (info.file.status === 'error') {
                _fileCnt++
                message.error(`${info.file.name} file upload failed.`);
            }
            if (_fileCnt >= info.fileList.length) {
                console.log('upload completed');
                message.loading("Filtering data with keywords");
                keywordFilter();
            }
        },
    };

    const columns = [
        {
            title: 'Excel Name',
            dataIndex: 'Excel_name',
            key: 'Excel_name',
        },
        {
            title: 'Count',
            dataIndex: 'cnt',
            key: 'cnt',
        },
    ];

    const { Column } = Table;

    return (
        <>
            <Row>
                <Col span={24}>
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                        }}
                        size="large"
                    >
                        <Dragger
                            {...props}
                        >
                            {/*<Button icon={<UploadOutlined />}>Upload</Button>*/}
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload (Max 5 files)</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginTop: 30 + 'px' }}> 
                <Col>
                    <h3> Summary of uploaded file/records</h3>
                </Col>
                <Col>
                    <Button onClick={getSummary} style={{ marginLeft: 30 + 'px' }} type="primary" shape="circle" icon={<ReloadOutlined />} size={10} />
                </Col>
                <Col>
                    <Button onClick={delData} style={{ marginLeft: 30 + 'px' }} danger shape="circle" icon={<DeleteOutlined />} size={10} />
                </Col>
            </Row>
            <Row style={{ marginTop: 30 + 'px' }}> 
                <Col span={12}>
                    {/*<Table dataSource={dataSource} columns={columns} loading={isProgress} />*/}
                    <Table dataSource={dataSource} loading={isProgress}>
                        <Column title="Excel Name" dataIndex="Excel_name" key="Excel_name" />
                        <Column title="Total Record(s)" dataIndex="cnt" key="cnt" />
                        <Column
                            title="Action"
                            key="action"
                            render={(_, record, index) => (
                                <Space size="middle">
                                    <Button onClick={() => delRec(_, record, index)} loading={isRecProgress[index]} danger shape="circle" icon={<DeleteOutlined />} size={5} />
                                </Space>
                            )}
                        />
                    </Table>
                </Col>                
            </Row>
         </>
    );
    
}
